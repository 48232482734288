$ = jQuery = require('jquery');

require('jquery-ui/ui/core');
require('bootstrap');
// require('fotorama/fotorama.js');

$(document).ready(function () {
    const collapseElementList = document.querySelectorAll('.collapse');
    const collapseList = [...collapseElementList].map(collapseEl => new bootstrap.Collapse(collapseEl));
});

